<template>
  <div class=" container-fluid container-fixed-lg">
    <div class="card card-transparent">
      <div class="card-header d-flex justify-content-between">
        <div class="page-title card-title">
          {{ $t('auth.profile') }}
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-8 col-md-10">
            <div data-pages="card" class="card card-default">
              <div class="card-header">
              </div>
              <div class="card-body">
                <ValidationObserver ref="form_update_basic_profile" v-slot="{ passes, valid, validated }">
                  <form novalidate @submit.prevent="passes(updateProfile)">
                    <AppInput type="email" name="email" :label="$t('common.mail address')" rules="required"
                              v-model="user.email"/>
                    <AppInput type="string" name="name" :label="$t('common.name')" rules="required"
                              v-model="user.name"/>
                    <AppInput type="number-length:11" name="phone" :label="$t('common.phone')" rules="required|phone"
                              v-model="user.phone"/>
                    <AppInput ref="current_password" name="current_password" type="password"
                              :label="$t('common.current_password')"
                              rules="required_if:new_password|min:6" autocomplete="new-password"
                              v-model="changePass.old"/>
                    <AppInput name="new_password"
                              :type="[showNewPassword ? 'text' : 'password']"
                              :label="$t('common.new_password')"
                              rules="required_if:current_password|password|min:6"
                              v-model="changePass.new">
                      <template v-slot:append>
                        <i style="font-size: 25px; cursor: pointer" @click="showNewPassword = !showNewPassword"
                           :class="['fa', showNewPassword ? 'fa-eye' : 'fa-eye-slash']"></i>
                      </template>
                    </AppInput>
                    <AppInput name="new_password_confirm" :type="[showNewConfirmPassword ? 'text' : 'password']"
                              :label="$t('common.new_password_confirm')"
                              rules="required_if:current_password|confirmed:new_password"
                              v-model="changePass.new_confirm">
                      <template v-slot:append>
                        <i style="font-size: 25px; cursor: pointer"
                           @click="showNewConfirmPassword = !showNewConfirmPassword"
                           :class="['fa', showNewConfirmPassword ? 'fa-eye' : 'fa-eye-slash']"></i>
                      </template>
                    </AppInput>
                    <button :disabled="!valid && validated" type="submit"
                            :class="['btn inline pull-right', 'btn-complete']">
                      {{ $t('common.save') }}
                    </button>
                    <button @click="$router.pushByName(routeDashboard)" type="button"
                            :class="['btn btn-default btn-cons pull-right btn-modal-cancel']">
                      {{ $t('common.cancel') }}
                    </button>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import ROUTERS from "@consts/routes";

export default {
  data() {
    const authKey = this.$store.state.setting.authType
    var routeDashboard = '';
    switch (authKey) {
      case 'adminAuth':
        routeDashboard = ROUTERS.ADMIN.DASHBOARD;
        break;
      case 'csAuth':
        routeDashboard = ROUTERS.CS.DASHBOARD;
        break;
      case 'hotelAuth':
        routeDashboard = ROUTERS.HOTEL.DASHBOARD;
        break;
      case 'taxiAuth':
        routeDashboard = ROUTERS.TAXI.DASHBOARD;
        break;
    }
    return {
      user: {},
      showNewPassword: false,
      showNewConfirmPassword: false,
      changePass: {
        old: null,
        new: null,
        new_confirm: null,
      },
      routeDashboard: routeDashboard
    }
  },
  mounted() {
    this.$request.get(this.$consts.API.PROFILE.USER).then(res => {
      if (!res.hasErrors()) {
        this.user = res.data.data
        this.changePass.old = null
      }
    })
  },
  methods: {
    async updateProfile() {
      const res = await this.$request.post(this.$consts.API.PROFILE.UPDATE_PROFILE, {
        name: this.user.name,
        email: this.user.email,
        phone: this.user.phone,
        current_password: this.changePass.old,
        new_password: this.changePass.new,
        new_password_confirm: this.changePass.new_confirm,
      })
      if (!res.hasErrors()) {
        this.changePass = {};
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$store.commit(`${this.$store.state.setting.authType}/updateUser`, this.user)
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_basic_profile.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
  }
}
</script>
